<template>
  <div class="stepGroup">
    <div class="step">
      <div class="item" :class="{ isStep: step < 3, successStep: step > 1 }">
        <span>1</span>{{ $fanyi("填写个人信息") }}
      </div>
      <div class="border"></div>
      <div class="item" :class="{ isStep: step == 3, successStep: step > 3 }">
        <span>2</span>{{ $fanyi("校验") }}
      </div>
      <div class="border"></div>
      <div class="item" :class="{ isStep: step == 4 }">
        <span>3</span>{{ $fanyi("Rakumart经验") }}
      </div>
      <div class="border"></div>
      <div class="item" :class="{ isStep: step == 5 }">
        <span>4</span>{{ $fanyi("完成注册") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["step"],
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.stepGroup {
  width: 1400px;
  padding: 66px 0 40px;
  margin: 0 auto;
  border-left: #e8e8e8 solid 1px;
  border-right: #e8e8e8 solid 1px;
  background-color: #fff;
  .step {
    width: 1194px;
    display: flex;
    justify-content: cenetr;
    margin: 0 auto;

    .item {
      color: #999;
      text-align: center;
      font-size: 15.559px;

      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-color: #bed7f0;
        border: 4px solid #bed7f0;
        color: white;
        border-radius: 50%;
        margin-right: 12px;
        display: inline-flex flex;
        justify-content: center;
        align-items: center;
        margin-right: 9px;
      }
    }
    .isStep {
      color: #000;
      span {
        background-color: #1355ab;
        border-color: #1355ab;
        color: #fff;
      }
    }
    .successStep {
      color: #8f94cb;
      span {
        background-color: #8f94cb;
        border-color: #dfebf8;
        color: #fff;
      }
    }
    .border {
      flex: 1;
      height: 1px;
      background-color: #d7d7d7;
      margin: 14px 28px 0;
    }
  }
}
</style>
