<template>
  <div class="obody">
    <HomePageTop></HomePageTop>

    <!-- 注册主体 -->
    <div class="logo-box main">
      <!-- logo S -->
      <div class="fl fenge"></div>
      <h2 class="fl">{{ $fanyi("创建新帐户") }}</h2>
      <div class="login fr">
        <span class="oicon">i</span>
        <a
          href="javascript:;"
          @click="
            $store.commit('getactivePage', '/');
            $fun.routerToPage('/login');
          "
          >{{ $fanyi("已经有账户了?登录") }}</a
        >
      </div>
      <!-- logo E -->
    </div>
    <!-- 步骤显示 -->
    <stepGroup :step="step" />

    <!-- 第一步页面 -->
    <div class="register" v-if="step == 1">
      <div>
        <h3 class="firstStepTitle">
          <p class="xuYaoXinXi">{{ $fanyi("专业买家和公司免费注册") }}</p>
        </h3>
        <div class="tiShi">
          <p>
            {{ $fanyi("在以下字段中填写您的业务或公司信息:") }}
          </p>
        </div>

        <el-form
          class="firStepForm"
          :model="formregister"
          :rules="formRules"
          ref="formRef"
        >
          <ul>
            <li>
              <!-- 名字 -->
              <el-form-item prop="user_name" :label="$fanyi('名字')">
                <input
                  v-bind:class="{
                    istrueInput: formInputTrue.user_name,
                  }"
                  class="ipt"
                  @focus="validateField('user_name')"
                  @blur="validateField('user_name')"
                  v-model="formregister.user_name"
                  :placeholder="$fanyi('请输入名字')"
                />
                <div class="gou" v-if="formInputTrue.user_name">✔</div>
              </el-form-item>
            </li>
            <li>
              <!-- 登录名 -->
              <el-form-item prop="user_last_name" :label="$fanyi('姓氏')">
                <input
                  class="ipt"
                  v-bind:class="{
                    istrueInput: formInputTrue.user_last_name,
                  }"
                  @focus="validateField('user_last_name')"
                  @blur="validateField('user_last_name')"
                  autocomplete="new-password"
                  v-model="formregister.user_last_name"
                  :placeholder="$fanyi('请输入姓氏')"
                />
                <div class="gou" v-if="formInputTrue.user_last_name">✔</div>
              </el-form-item>
            </li>
            <li>
              <!-- 人口/城市 -->
              <el-form-item prop="city" :label="$fanyi('人口/城市')">
                <input
                  class="ipt"
                  v-bind:class="{
                    istrueInput: formInputTrue.city,
                  }"
                  @focus="validateField('city')"
                  @blur="validateField('city')"
                  autocomplete="new-password"
                  v-model="formregister.city"
                  :placeholder="$fanyi('输入您公司或企业所在的城市/人口')"
                />
                <div class="gou" v-if="formInputTrue.city">✔</div>
              </el-form-item>
            </li>
            <li>
              <!-- 公司名称 -->
              <el-form-item prop="company_name" :label="$fanyi('公司名称')">
                <input
                  class="ipt"
                  v-bind:class="{
                    istrueInput: formInputTrue.company_name,
                  }"
                  @focus="validateField('company_name')"
                  @blur="validateField('company_name')"
                  autocomplete="new-password"
                  v-model="formregister.company_name"
                  :placeholder="$fanyi('请输入公司名称')"
                />
                <div class="gou" v-if="formInputTrue.company_name">✔</div>
              </el-form-item>
            </li>
            <li>
              <!-- 省份 -->
              <el-form-item :label="$fanyi('省份')" prop="country">
                <el-select
                  class="xuanZeGuoJia select"
                  name=""
                  @focus="validateField('country')"
                  @blur="validateField('country')"
                  @change="updata"
                  v-model="formregister.country"
                >
                  <el-option
                    v-for="(ddd, index) in $imdata.provincesList"
                    :key="ddd + index"
                    :label="ddd"
                    :value="ddd"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </li>
            <li>
              <!-- 你的商业网站(非强制性) -->
              <el-form-item
                prop="company_web"
                :label="$fanyi('你的商业网站(非强制性)')"
              >
                <input
                  class="ipt"
                  v-bind:class="{
                    istrueInput: formInputTrue.company_web,
                  }"
                  @focus="validateField('company_web')"
                  @blur="validateField('company_web')"
                  autocomplete="new-password"
                  v-model="formregister.company_web"
                  :placeholder="$fanyi('请输入公司网址')"
                />
                <div class="gou" v-if="formInputTrue.company_web">✔</div>
              </el-form-item>
            </li>
            <li>
              <!-- 国家 -->
              <el-form-item prop="nation" :label="$fanyi('国家')">
                <input
                  class="ipt"
                  v-bind:class="{
                    istrueInput: formInputTrue.nation,
                  }"
                  @blur="validateField('nation')"
                  v-model="formregister.nation"
                  :placeholder="$fanyi('输入您的公司或企业所在的国家')"
                />
                <div class="gou" v-if="formInputTrue.nation">✔</div>
                <div
                  class="showInputNationTip"
                  v-if="formInputTrue.nation != false"
                >
                  {{ $fanyi("*我们只从中国进口到西班牙") }}
                </div>
              </el-form-item>
            </li>

            <li>
              <!-- 业务类型 -->
              <el-form-item prop="user_type" :label="$fanyi('业务类型')">
                <el-select
                  class="select"
                  v-bind:class="{
                    istrueInput: formInputTrue.user_type,
                  }"
                  @blur="validateField('user_type')"
                  @change="
                    updata();
                    validateField('user_type');
                  "
                  v-model="formregister.user_type"
                  :placeholder="$fanyi('选择业务类型')"
                >
                  <el-option
                    :label="$fanyi('我是公司的')"
                    :value="$fanyi('我是公司的')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('我是自主的')"
                    :value="$fanyi('我是自主的')"
                  ></el-option>
                  <!-- <el-option
                    :label="$fanyi('我是特别的')"
                    :value="$fanyi('我是特别的')"
                  ></el-option> -->
                </el-select>
                <!-- <div class="gou" v-if="formInputTrue.user_type">✔</div> -->
              </el-form-item>
            </li>
            <li>
              <!-- 电子邮件 -->
              <el-form-item prop="email" :label="$fanyi('电子邮件')">
                <input
                  class="ipt"
                  v-bind:class="{
                    istrueInput: formInputTrue.email,
                  }"
                  @focus="validateField('email')"
                  @blur="validateField('email')"
                  v-model="formregister.email"
                  :placeholder="$fanyi('请输入邮箱')"
                />
                <div class="gou" v-if="formInputTrue.email">✔</div>
              </el-form-item>
            </li>
            <li>
              <!-- 你的主要业务领域 -->
              <el-form-item
                prop="industry_type"
                :label="$fanyi('你的主要业务领域')"
              >
                <el-select
                  class="select"
                  v-bind:class="{
                    istrueInput: formInputTrue.industry_type,
                  }"
                  @blur="validateField('industry_type')"
                  @change="
                    updata();
                    validateField('industry_type');
                  "
                  v-model="formregister.industry_type"
                  :placeholder="$fanyi('选择行业')"
                >
                  <el-option
                    :label="$fanyi('家庭和装饰部门')"
                    :value="$fanyi('家庭和装饰部门')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('体育部门')"
                    :value="$fanyi('体育部门')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('健身行业')"
                    :value="$fanyi('健身行业')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('儿童部门')"
                    :value="$fanyi('儿童部门')"
                  ></el-option>

                  <el-option
                    :label="$fanyi('办公室和文具部门')"
                    :value="$fanyi('办公室和文具部门')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('酒店业')"
                    :value="$fanyi('酒店业')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('宠物/动物部门')"
                    :value="$fanyi('宠物/动物部门')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('化妆品行业')"
                    :value="$fanyi('化妆品行业')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('技术部门')"
                    :value="$fanyi('技术部门')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('美容/美发部门')"
                    :value="$fanyi('美容/美发部门')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('工具和专业部门')"
                    :value="$fanyi('工具和专业部门')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('汽车行业')"
                    :value="$fanyi('汽车行业')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('时尚与配饰行业')"
                    :value="$fanyi('时尚与配饰行业')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('安全部门')"
                    :value="$fanyi('安全部门')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('多产品部门或集市')"
                    :value="$fanyi('多产品部门或集市')"
                  ></el-option>
                  <el-option
                    :label="$fanyi('其他部门')"
                    :value="$fanyi('其他部门')"
                  ></el-option>
                </el-select>
                <!-- <div class="gou" v-if="formInputTrue.industry_type">✔</div> -->
              </el-form-item>
            </li>
            <li class="guoJiaQuHao">
              <!-- 电话 -->
              <el-form-item :label="$fanyi('前缀')" prop="mobile">
                <div class="shouJIHaoCon">
                  <!-- <div
                    class="quHao"
                    v-bind:class="{
                      istrueInput: formInputTrue.mobile,
                    }"
                  >
                    {{ formregister.country_phone }}
                  </div> -->
                  <!-- @change="updata()" -->
                  <el-select
                    class="phoneCountrySelect select"
                    :class="{
                      istrueInput: formInputTrue.mobile,
                    }"
                    :key="phoneKeyKey"
                    @change="
                      phoneKeyKey++; // 如果已经填写了手机号就验证一遍手机号
                      if (formregister.mobile) {
                        validateField('mobile');
                        3;
                      }
                    "
                    filterable
                    v-model="formregister.country_phone"
                  >
                    <el-option
                      v-for="(item, index) in $imdata.countryAndAreaCode"
                      :key="index"
                      :label="item.sxval"
                      :value="item.value"
                    >
                      <!-- <div class="phoneSelectOption">
                        <span> {{ item.value }} </span>
                        <span>
                          {{ item.sx }}
                        </span>
                      </div> -->
                    </el-option>
                  </el-select>
                  <div class="oneRowTowName">
                    {{ $fanyi("电话") }}
                  </div>
                  <input
                    @focus="validateField('mobile')"
                    @blur="validateField('mobile')"
                    class="iptSmall"
                    v-bind:class="{
                      istrueInput: formInputTrue.mobile,
                    }"
                    oninput="this.value=this.value.replace(/[^\d]/g,'')"
                    v-model="formregister.mobile"
                    :placeholder="this.$fanyi('请输入手机号')"
                  />
                  <div class="gou" v-if="formInputTrue.mobile">✔</div>
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 身份证 -->
              <el-form-item prop="user_id_card" label="Nº fiscal CIF / NIF">
                <input
                  class="ipt"
                  v-bind:class="{
                    istrueInput: formInputTrue.user_id_card,
                  }"
                  @focus="validateField('user_id_card')"
                  @blur="validateField('user_id_card')"
                  v-model="formregister.user_id_card"
                  :placeholder="$fanyi('请输入CIF/NIF')"
                />
                <div class="gou" v-if="formInputTrue.user_id_card">✔</div>
              </el-form-item>
            </li>
            <li>
              <!-- 密码 -->
              <el-form-item
                prop="password"
                :label="$fanyi('密码')"
                class="regerror"
              >
                <el-input
                  type="password"
                  class="ipt passWordInputBox"
                  v-bind:class="{
                    elistrueInput: formInputTrue.password,
                  }"
                  @focus="validateField('password')"
                  @blur="validateField('password')"
                  autocomplete="new-password"
                  show-password
                  v-model="formregister.password"
                  :placeholder="$fanyi('请输入密码')"
                />
              </el-form-item>
            </li>
            <li>
              <!-- 密码确认 -->
              <el-form-item prop="repassword" :label="$fanyi('密码确认')">
                <el-input
                  class="ipt passWordInputBox"
                  type="password"
                  v-bind:class="{
                    elistrueInput: formInputTrue.repassword,
                  }"
                  @focus="validateField('repassword')"
                  @blur="validateField('repassword')"
                  autocomplete="new-password"
                  show-password
                  v-model="formregister.repassword"
                  :placeholder="$fanyi('请再次输入密码')"
                />
              </el-form-item>
            </li>

            <li class="yanzhengma">
              <!-- 验证码 -->
              <el-form-item
                label="Código de verificación"
                prop="num"
                v-if="false"
              >
                <div class="yanzhengmaBox">
                  <div class="input">
                    <input
                      v-bind:class="{
                        istrueInput: !!formInputTrue.num,
                      }"
                      class="iptSmall"
                      @focus="validateField('num')"
                      @blur="validateField('num')"
                      v-model="formregister.num"
                      :placeholder="this.$fanyi('请输入验证码')"
                    />
                    <div class="gou regCodegou" v-if="formInputTrue.num">
                      ✔
                    </div>
                  </div>

                  <button @click.prevent="openODialog(true)" v-show="show">
                    <!-- 发送验证码 -->{{ $fanyi("发送验证码") }}
                  </button>
                  <button v-show="!show" @click.prevent style="opacity: 0.6">
                    {{ Countdown }} S
                  </button>
                </div>
              </el-form-item>
            </li>
            <li>
              <el-form-item
                :label="$fanyi('同意以上协议，进入下一步')"
                class="xieYi"
              >
                <div class="fuWuXieYi">
                  <button class="btn" @click.prevent="getNext">
                    <!-- 同意以上协议，进入下一步 -->
                    {{ $fanyi("同意以上协议，进入下一步") }}
                  </button>
                  <div class="tongYiXieYi">
                    <el-checkbox v-model="agreement"></el-checkbox>
                    <span class="xieYiwenzi">
                      He leído y acepto la
                      <span
                        class="Link"
                        @click="$fun.routerToPage('/politica-privacidad')"
                        >política de privacidad</span
                      >
                      y autorizo el envío de comunicaciones sobre los productos
                      de Rakumart.
                    </span>
                  </div>
                </div>
              </el-form-item>
            </li>
            <li></li>
          </ul>
        </el-form>
      </div>
    </div>
    <!-- 第二步页面 确认注册项 -->
    <div
      class="registerInformation register"
      v-if="step == 2"
      v-loading="loading"
    >
      <div>
        <h3 class="firstStepTitle">
          <p class="xuYaoXinXi">{{ $fanyi("专业买家和公司免费注册") }}</p>
        </h3>

        <el-form
          class="firStepForm"
          :model="formregister"
          :rules="formRules"
          ref="formRef"
        >
          <ul>
            <li>
              <!-- 名字 -->
              <el-form-item prop="user_name" :label="$fanyi('名字')">
                <div class="showregisterInfo">
                  {{ formregister.user_name }}
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 登录名 -->
              <el-form-item prop="user_last_name" :label="$fanyi('姓氏')">
                <div class="showregisterInfo">
                  {{ formregister.user_last_name }}
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 人口/城市 -->
              <el-form-item prop="user_last_name" :label="$fanyi('人口/城市')">
                <div class="showregisterInfo">
                  {{ formregister.city }}
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 公司名称 -->
              <el-form-item prop="company_name" :label="$fanyi('公司名称')">
                <div class="showregisterInfo">
                  {{ formregister.company_name }}
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 省份 -->
              <el-form-item prop="company_name" :label="$fanyi('省份')">
                <div class="showregisterInfo">
                  {{ formregister.country }}
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 你的商业网站(非强制性) -->
              <el-form-item
                prop="company_web"
                :label="$fanyi('你的商业网站(非强制性)')"
              >
                <div class="showregisterInfo">
                  {{ formregister.company_web }}
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 国家 -->
              <el-form-item :label="$fanyi('国家')" prop="nation">
                <div class="showregisterInfo">
                  {{ formregister.nation }}
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 业务类型 -->
              <el-form-item prop="user_type" :label="$fanyi('业务类型')">
                <div class="showregisterInfo">
                  {{ $fanyi(formregister.user_type) }}
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 电子邮件 -->
              <el-form-item prop="email" :label="$fanyi('电子邮件')">
                <div class="showregisterInfo">
                  {{ formregister.email }}
                </div>
              </el-form-item>
            </li>

            <li>
              <!-- 你的主要业务领域 -->
              <el-form-item
                prop="industry_type"
                :label="$fanyi('你的主要业务领域')"
              >
                <div class="showregisterInfo">
                  {{ $fanyi(formregister.industry_type) }}
                </div>
              </el-form-item>
            </li>
            <li class="guoJiaQuHao">
              <!-- 电话 -->
              <el-form-item :label="$fanyi('前缀')" prop="mobile">
                <!-- <select
                  name=""
                  v-model="formregister.country_phone"
                  class="phone"
                >
                  <option value="+34">{{ $fanyi("西班牙") }}</servicios-adicionales>
                </select> -->
                <div class="shouJIHaoCon">
                  <div
                    class="quHao"
                    style="border: 1px solid #dedede; background-color: #f3f3f3"
                  >
                    {{
                      $imdata.countryAndAreaCode.find((item) => {
                        return item.value == formregister.country_phone;
                      }).sx
                    }}
                  </div>
                  <div class="oneRowTowName">
                    {{ $fanyi("电话") }}
                  </div>
                  <div class="showregisterInfo">
                    {{ formregister.mobile }}
                  </div>
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 身份证 -->
              <el-form-item prop="user_id_card" label="Nº fiscal CIF / NIF">
                <div class="showregisterInfo">
                  {{ formregister.user_id_card }}
                </div>
              </el-form-item>
            </li>
            <li>
              <!-- 密码 -->
              <el-form-item prop="password" label="contraseña">
                <div class="showregisterInfo" v-if="showPassword">
                  ●●●●●●●●●●●●●
                </div>
                <div class="showregisterInfo" v-else>
                  {{ formregister.password }}
                </div>
                <i
                  class="el-icon-view allDataShow"
                  @click="showPassword = !showPassword"
                ></i>
              </el-form-item>
            </li>

            <li>
              <el-form-item label=" " class="xiaYiBuHuoFanHui">
                <div class="caoZuo">
                  <button class="shangYiBu" @click.prevent="step = 1">
                    {{ $fanyi("返回重新编辑") }}
                  </button>
                  <button class="xiaYiBu" @click.prevent="openODialog">
                    {{ $fanyi("个人信息确认") }}
                  </button>
                </div>
              </el-form-item>
            </li>

            <li></li>
          </ul>
        </el-form>
      </div>
    </div>
    <transition name="right-slide">
      <verificationCode
        ref="VerificationCode"
        v-if="step == 3"
        :email="formregister.email"
        @nextStep="
          (code) => {
            formregister.num = code;
            step = 4;
          }
        "
        @lastStep="
          () => {
            step = 2;
          }
        "
      />
    </transition>
    <!-- 第二步页面 选择偏好-->
    <transition name="right-slide">
      <choosePreferences
        v-if="step == 4"
        v-model="formregister"
        :nextStep="confirmformRegister"
        @lastStep="
          () => {
            step = 2;
          }
        "
      />
    </transition>
    <div class="fooot">
      <Foot></Foot>
    </div>
    <oDialog ref="ODialog">
      <div class="humanMachineVBox">
        <div class="humanMachineVImgBox">
          <img :src="imgBlob" alt="" />
          <span @click="openODialog()">{{ $fanyi("刷新") }}</span>
        </div>
        <div class="humanMachineVBtnBox">
          <input type="text" v-model="imgRegValue" @keydown.enter="sendSMS" />
          <button @click="sendSMS">
            {{ $fanyi("确认") }}
          </button>
        </div>
      </div>
    </oDialog>
  </div>
</template>

<script>
import stepGroup from "./components/register-model-stepGroup.vue";
import choosePreferences from "./components/register-model-choosePreferences.vue";
import verificationCode from "./components/model-verificationCode.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot.vue";
import oDialog from "../../components/public/Dialog.vue";
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$fanyi("请再次输入密码")));
      } else if (value !== this.formregister.password) {
        callback(new Error(this.$fanyi("两次输入密码不一致!")));
      } else {
        callback();
      }
    };

    // 字段校验
    var FieldCalibration = (rule, value, callback) => {
      this.$api
        .checkRegisterField({
          field: rule.field,
          value: value,
        })
        .then((res) => {
          //console.log("checkRegisterField", res);
          if (res.success == false) {
            callback(new Error(this.$fanyi("已被使用")));
          } else {
            callback();
          }
        });
    };
    // 对比验证码
    var ContrastVerificationCode = (rule, value, callback) => {
      //console.log(rule);
      let updata = {
        occasion: "register",
        number: this.formregister[this.formregister.tools],
        code: value,
      };

      this.$api.checkVerificationCode(updata).then((res) => {
        if (res.success == false) {
          this.regcode = false;
          callback(new Error(this.$fanyi(res.msg)));
        } else {
          this.regcode = true;
          callback();
        }
      });
    };
    return {
      phoneKeyKey: 2, //用来刷新电话区号选择框,如果用'this.$forceUpdate();'刷新会触发el-form的表单验证,所以使用key刷新组件
      loading: false,
      showPassword: true,
      // 用户登录地址信息
      User_login_address_information: {},
      regcode: false, //验证码是否错误
      step: 1,
      formInputTrue: {},
      // 注册表单
      formregister: { market_channel: [] },
      agreement: false,
      // 表单验证
      formRules: {
        user_last_name: [
          {
            required: true,
            message: this.$fanyi("请输入姓氏"),
            trigger: "blur",
          },
        ],
        user_id_card: [
          {
            required: true,
            message: this.$fanyi("请输入CIF/NIF"),
            trigger: "blur",
          },
        ],
        nation: [
          {
            required: true,
            message: this.$fanyi("不能为空"),
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: this.$fanyi("不能为空"),
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: this.$fanyi("不能为空"),
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: true,
            message: this.$fanyi("不能为空"),
            trigger: "blur",
          },
        ],
        company_web: [],
        user_name: [
          {
            required: true,
            message: this.$fanyi("请输入名字"),
            trigger: "blur",
          },
        ],

        mobile: [
          {
            required: true,
            message: this.$fanyi("手机号为空"),
            trigger: "blur",
          },

          {
            validator: FieldCalibration,
            trigger: "blur",
          },
        ],
        num: [
          {
            required: true,
            message: this.$fanyi("请填写确认码"),
            trigger: "blur",
          },
          // {
          //   pattern: /^\d{6}$/,
          //   message: "请输入6位数字",
          //   trigger: "blur",
          // },
          {
            validator: ContrastVerificationCode,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$fanyi("请输入正确的邮箱地址"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$fanyi("请输入正确的邮箱地址"),
            trigger: ["blur", "change"],
          },
          {
            validator: FieldCalibration,
            trigger: "blur",
          },
        ],
        user_type: [
          {
            required: true,
            message: this.$fanyi("不能为空"),
            trigger: "blur",
          },
        ],
        industry_type: [
          {
            required: true,
            message: this.$fanyi("不能为空"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/,
            message: this.$fanyi("请输入6-16位包含大小写字母和数字的字符串"),
          },
        ],
        repassword: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
      },
      regip: "1.1.1.1",
      num: "",
      show: true,
      Countdown: 60,
      imgBlob: "",
      imgRegValue: "",
      timer: null,
    };
  },
  // mounted() {
  // 	this.$jsonp('https://pv.sohu.com/cityjson?ie=utf-8').then((res)=>{
  // 	　	//console.log(res);
  // 	})
  // },
  created() {
    this.formregister.country = "Álava";
    this.formregister.country_phone = "+34";
    this.formregister.tools = "email";
  },
  components: {
    Foot,
    HomePageTop,
    oDialog,
    choosePreferences,
    stepGroup,
    verificationCode,
  },
  methods: {
    openODialog(replace) {
      if (!this.formregister.email) {
        this.$message.warning(this.$fanyi("请输入邮箱"));
        return false;
      }
      return this.sendSMS();

      if (replace) this.imgRegValue = "";

      this.$api
        .codeImg({
          number: this.formregister.email,
        })
        .then((res) => {
          this.imgBlob = res.data.image;

          //接下来的操作
          this.$refs.ODialog.open("", () => {}, "", {
            width: "600px",
            showBtn: false,
            close_on_click_modal: false,
          });
        });
    },
    updata() {
      this.$forceUpdate();
    },
    // 手动触发表单验证
    validateField(type) {
      if (this.formregister[type])
        this.formregister[type] = this.formregister[type].trim();
      this.$refs.formRef.validateField(type, (v) => {
        if (v == "") {
          this.formInputTrue[type] = true;
        } else {
          this.formInputTrue[type] = false;
        }
        this.$forceUpdate();
      });
    },
    // 从第一步到第二步
    getNext() {
      if (!this.formregister.country) {
        return this.$message.error(this.$fanyi("必填参数缺失"));
      }
      if (!this.agreement)
        return this.$message.error(
          this.$fanyi("使用条款和隐私政策还没有被同意。")
        );
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.step = 2;
        }
      });
      //console.log(this.formregister.user_last_name);
      var params = new FormData();
      //console.log(params);
    },
    // 确认注册
    confirmformRegister() {
      if (!this.formregister.country) {
        return this.$message.error(this.$fanyi("必填参数缺失"));
      }

      let list = {};
      for (let i in this.formregister) {
        list[i] = this.formregister[i];
      }
      list.verification = {
        number: this.formregister[this.formregister.tools],
        code: this.formregister.num,
      };
      // 假如是讲师系统推荐链接进入就把邀请码也放进去
      if (this.$route.query.promote) {
        list.promote = this.$route.query.promote;
      }
      this.loading = true;
      list.client_tool = 1;
      list.market_channel = JSON.stringify(list.market_channel);
      this.$api.register(list).then((res) => {
        if (res.code != 0) return;
        this.$fun.routerToPage("/register-success");
        this.loading = false;
        sessionStorage.setItem("login_account", list.mobile);
        sessionStorage.setItem("password", list.password);
      });
    },
    // 短信验证
    sendSMS() {
      if (this.Countdown != 60) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.Countdown = 60;
      }
      let list = {
        occasion: "register",
        tool: this.formregister.tools,
        number: this.formregister.email,
        area_code: this.formregister.country_phone,
      };
      // if (this.formregister.tools == "email") {
      //   list.number = this.formregister.email;
      // } else {
      //   list.number = this.formregister.mobile;
      //   list.area_code = this.formregister.country_phone;
      // }
      // 2023/6/7 发送验证码时需要传递用户已填写的信息,因为越早获取到越好
      // for (let i in this.formregister) {
      //   list[i] = this.formregister[i];
      // }
      // list.captcha = this.imgRegValue;
      this.show = false;
      this.Countdown = 60;
      this.step = 3;
      this.$api.sendVerificationCode(list).then((res) => {
        this.show = true;
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        // 60秒倒计时
        this.show = false;
        this.$refs.ODialog.visible = false;

        this.timer = setInterval(() => {
          this.Countdown--;
          if (this.Countdown <= 0) {
            this.show = true;
            this.Countdown = 60;
            if (this.timer) {
              clearInterval(this.timer);
            }
          }
        }, 1000);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/mixin";

.main {
  background-color: white;
  width: $pageWidth;
  margin: 10px auto 0;
  padding-bottom: 30px;
  border: solid 1px #e8e8e8;
  border-radius: 6px 6px 0 0;
}

.header {
  height: 30px;
  width: 100%;
  background: #eee;
}

/deep/ .input__inner {
  border-radius: 0;
}

.logo-box {
  height: 80px;
  overflow: hidden;
  border-bottom: 1px solid #ddd;

  .fenge {
    width: 1px;
    height: 35px;
    margin: 22px 10px 0 50px;
    background-color: $homePageSubjectColor;
  }

  h2 {
    line-height: 80px;
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }

  .login {
    margin: 30px 50px 0 0;

    &.fr {
      $loginIconSize: 14px;
      .oicon {
        display: inline-block;
        text-align: center;
        margin-right: 5px;
        font-size: 12px;
        line-height: $loginIconSize;
        font-weight: 400;
        color: #ffffff;
        width: $loginIconSize;
        height: $loginIconSize;
        background: $homePageSubjectColor;
        border-radius: 50%;
      }
    }

    span {
      font-size: 18px;
      font-weight: bold;
      color: #999;
    }

    a {
      font-size: 14px;
      font-weight: 400;
      color: #404040;
    }
  }
}

.register {
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 80px;
  background-color: #fff;
  border: solid 1px #e8e8e8;
  border-top: none;
  border-radius: 0 0 6px 6px;
  > div {
    width: 850px;
    min-height: 600px;
    background: #ffffff;
    border: 1px solid white;
    margin: 0 auto;
    .tiShi {
      margin-bottom: 47px;
      p {
        display: block;
        height: 17px;
        font-size: 14px;
        font-weight: 400;
        color: #7a7a7a;
        line-height: 20px;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .firstStepTitle {
    margin-bottom: 16px;
    .xuYaoXinXi {
      height: 21px;
      font-size: 18px;

      font-weight: 600;
      color: #2f2f2f;
      margin-bottom: 15px;
    }
  }
  .firStepForm {
    width: 850px;
    /deep/.el-form-item__error {
      top: 50px;
    }
    > ul {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      > li {
        width: 379px;
        display: flex;
        flex-direction: column;
        // 表单标题样式
        /deep/.el-form-item__label {
          padding: 0;
          text-align: left;
          float: none;
          display: block;
          width: 100%;
          line-height: 18px;
          height: 18px;
          margin-bottom: 10px;
          &::before {
            display: none;
          }
        }
        /deep/.elistrueInput .el-input__inner {
          background: #f3f6fc;
          border: 1px solid #abbbd2;
          border-radius: 6px;
          position: relative;
        }

        input {
          width: 379px;
          height: 48px;
          background: #ffffff;
          border: 1px solid #dedede;

          border-radius: 6px;
          padding: 0 10px;
          transition: 0.4s;
          &::placeholder {
            color: #b6b6b6;
          }
          &:focus {
            border: 1px solid $homePageSubjectColor;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
          }
          &.istrueInput {
            background: #f3f6fc;
            border: 1px solid #abbbd2;
            border-radius: 6px;
            position: relative;
          }
        }

        .gou {
          position: absolute;
          font-weight: bold;
          right: 13px;
          top: 3px;
          color: #417db4;
          &.regCodegou {
            right: 222px;
          }
        }
        .showInputNationTip {
          position: absolute;
          top: 39px;
        }
        .xuanZeGuoJia {
          background: #f3f6fc;
          /deep/.el-input__inner {
            background: #f3f6fc;
            border: 1px solid #abbbd2;
          }
          /deep/.el-icon-arrow-up {
            color: $homePageSubjectColor;
          }
        }
        .select {
          width: 100%;
          height: 48px;
          border-radius: 6px;
          /deep/.el-input__inner {
            width: 100%;
            height: 48px;
            border-radius: 6px;
          }
          /deep/.el-icon-arrow-up {
            font-weight: bold;
            font-size: 15px;
          }
          &.istrueInput {
            background: #f3f6fc;
            /deep/.el-input__inner {
              background: #f3f6fc;
              border: 1px solid #abbbd2;
            }
            /deep/.el-icon-arrow-up {
              color: $homePageSubjectColor;
            }
          }
        }
      }
      .guoJiaQuHao {
        position: relative;

        .shouJIHaoCon {
          display: flex;
          align-items: center;
          .phoneCountrySelect {
            flex: 0 0 80px;
            margin-right: 10px;
          }
          .quHao {
            flex: 0 0 80px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #dedede;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            &.istrueInput {
              background: #f3f6fc;
              border: 1px solid #abbbd2;
              border-radius: 6px;
              position: relative;
            }
          }
          .oneRowTowName {
            position: absolute;
            left: 90px;
            top: -39px;
            font-size: 14px;
            color: #606266;
          }
          input {
            width: 312px;
          }
        }
      }
      .yanzhengma {
        .yanzhengmaBox {
          display: flex;
          align-items: center;
          input {
            width: 167px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #dedede;
            border-radius: 6px;
            &.istrueInput {
              background: #f3f6fc;
              border: 1px solid #abbbd2;
              border-radius: 6px;
              position: relative;
            }
          }
          button {
            margin-left: 80px;
            width: 195px;
            height: 48px;
            background: $homePageSubjectColor;
            border: 1px solid $homePageSubjectColor;
            border-radius: 6px;
            font-size: 14px;
            color: #ffffff;
          }
        }
      }
      .xieYi {
        width: 379px;

        .fuWuXieYi {
          display: flex;
          align-items: flex-start;
          position: relative;
          button {
            width: 100%;
            height: 48px;
            background: #47bd9b;
            border: 1px solid #47bd9b;
            border-radius: 6px;
            color: white;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }

          .tongYiXieYi {
            position: absolute;
            width: 379px;
            top: 64px;
            display: flex;
            justify-content: space-between;
            /deep/.el-checkbox {
              display: flex;
              align-items: flex-start;
            }

            .xieYiwenzi {
              width: 359px;
              height: 41px;
              font-size: 12px;
              font-weight: 300;
              color: #2f2f2f;
              line-height: 14px;

              .Link {
                color: #1e2997;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
      .xiaYiBuHuoFanHui {
        .caoZuo {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          button {
            width: 45%;
            height: 48px;
            background: #47bd9b;
            border: 1px solid #47bd9b;
            border-radius: 6px;
            color: white;
          }
          .shangYiBu {
            background: $homePageSubjectColor;
            border-color: $homePageSubjectColor;
          }
          .xiaYiBu {
            background: #47bd9b;
          }
        }
      }
    }
    .showregisterInfo {
      width: 379px;
      height: 48px;
      font-size: 13px;
      line-height: 48px;
      border: 1px solid #dedede;
      background-color: #f3f3f3;
      // color: #787777;
      border-radius: 6px;
      padding: 0 10px;
      transition: 0.4s;
    }
  }
  // 表单错误提示样式
}

.registerInformation {
  width: 1400px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  padding: 0 50px 50px;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;

  > .registerInformationCon {
    width: 900px;
    height: 480px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    margin: 0 auto;

    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    padding: 50px;
    flex-direction: column;
    // justify-content: space-around;
    background-color: white;

    > .registerInformationTitle {
      font-size: 24px;

      color: #000000;
      line-height: 24px;

      margin-bottom: 60px;
    }

    > .registerInformationOpt {
      margin-bottom: 20px;

      > label {
        color: #000000;

        font-size: 14px;
        font-weight: bold;
        width: 314px;
        display: inline-block;
        text-align: right;
        margin-right: 40px;
      }

      > span {
        font-size: 14px;
      }

      &:last-child {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        > button {
          padding: 0 12px;
          height: 26px;
          border-radius: 6px;
          border: 1px solid $homePageSubjectColor;
          background-color: white;
          color: $homePageSubjectColor;
          margin-right: 20px;
          &:last-child {
            background-color: $homePageSubjectColor;
            color: white;
            margin-right: 0;
          }
        }
      }
    }
  }
}

.registration_complete {
  width: 1400px;
  margin: 0 auto;
  text-align: center;
  padding: 0 80px 200px;
  background-color: white;

  > .registration_completeCon {
    margin: 0 auto;
    width: 900px;
    height: 306px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    .registration_completeConOpt {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0px;
      }
      .successImg {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      span {
        font-size: 14px;

        line-height: 20px;
      }
      button {
        min-width: 195px;
        background: $homePageSubjectColor;
        border-radius: 6px;
        padding: 0 40px;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        height: 54px;
        border-radius: 6px;
      }
    }
  }
}

/deep/.el-checkbox__input {
  height: 16px;
}
.ologin {
  cursor: pointer;
  display: inline-block;
  font-size: 55px;
  color: $homePageSubjectColor;
  margin-bottom: 10px;
}
.passWordInputBox {
  /deep/.el-input__inner {
    height: 48px;
  }
}
.allDataShow {
  position: absolute;
  top: 16px;
  right: 20px;
  color: #999999;
  z-index: 999;
}

// 输入框验证失败样式
/deep/.is-error input {
  border-color: #dedede !important;
}
// 句子太长的提示语句样式
.regerror {
  /deep/.el-form-item__error {
    top: 90% !important;
  }
}
.fooot {
  margin-bottom: -20px;
  margin-top: 80px;
}
.humanMachineVBox {
  .humanMachineVImgBox {
    display: flex;
    padding: 0 35px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    img {
      width: 300px;
    }
    span {
      // width: 120px;
      margin-left: 20px;
      font-size: 20px;
      text-decoration: underline;
      color: #419dfb;
      cursor: pointer;
    }
  }
  .humanMachineVBtnBox {
    display: flex;
    align-items: center;
    padding: 0 35px;
    input {
      height: 48px;
      border: solid 1px #dfdfdf;
      border: solid 1px #dfdfdf;
      border-radius: 6px;
      flex: 1;
      font-size: 14px;
      margin-right: 20px;
      padding-left: 10px;
      &::placeholder {
        font-size: 22px;
        line-height: 70px;
      }
    }
    button {
      margin: auto;
      width: 120px;
      height: 48px;
      background: #1e2997;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s ease;
// }
</style>
<style lang="scss">
.phoneSelectOption {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
