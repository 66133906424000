<template>
  <div class="singleChoiceItem" @click="chioseChange">
    <div class="itemIcon" :class="{ ischecked: isChecked }">
      <img
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202404/661e23567b418.svg"
        alt=""
      />
    </div>
    <span>{{ label }}</span>
  </div>
</template>
<script>
export default {
  props: { value: Array, val: [String, Number], label: String },
  model: {
    prop: "value",
    event: "upData",
  },
  data() {
    return {
      modelValue: this.value || [],
    };
  },
  components: {},
  computed: {
    isChecked() {
      if (this.modelValue.includes(this.val)) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
  methods: {
    setValue(v) {
      this.$emit("upData", v);
    },
    chioseChange() {
      if (this.isChecked) {
        let findIndex = this.modelValue.findIndex((ii) => ii == this.val);
        this.modelValue.splice(findIndex, 1);
      } else {
        this.modelValue.push(this.val);
      }
      this.setValue(this.modelValue);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.singleChoiceItem {
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 10px 14px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  transition: 0.3s;
  &:last-child {
    margin-bottom: 0;
  }
  .itemIcon {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    margin-right: 8px;
    img {
      width: 100%;
      height: 100%;
      display: none;
    }
  }
  .itemIcon.ischecked {
    img {
      display: block;
    }
  }
  span {
    color: var(--Gray-700, #344054);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}
.singleChoiceItem:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>
